/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';

// ----------------------------------------------------------------------

OrderRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function OrderRow({ row, selected }) {
  // Destructure relevant fields from the row data
  const {
    updatedAt,
    customerName,
    user,
    orderItems = [],
    valueOfOrder,
    status,
    remarks,
    orderId,
    date,
  } = row;

  return (
    <>
      <TableRow hover selected={selected}>
        {/* Salesperson */}
        <TableCell align="left" variant="subtitle2" sx={{ cursor: 'pointer' }}>
          {user?.fullName || '-'}
        </TableCell>

        {/* Date */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {date ? moment(date).format('DD-MM-YYYY') : '-'}
        </TableCell>

        {/* Customer Name */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {customerName || '-'}
        </TableCell>

        {/* Order ID */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {orderId || '-'}
        </TableCell>

        {/* Products */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {orderItems.length > 0
            ? orderItems
                .map((item) => `${item.products?.name || '-'} [Qty: ${item.quantity || 0}]`)
                ?.join(' & ')
            : '-'}
        </TableCell>

        {/* Value of Order */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {valueOfOrder || '-'}
        </TableCell>

        {/* Remarks */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {remarks || '-'}
        </TableCell>

        {/* Status */}
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {status || '-'}
        </TableCell>
      </TableRow>
    </>
  );
}
